import React from "react"
import {graphql} from "gatsby"
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage from "gatsby-background-image";
import Layout from "../components/Layout/Layout";
import styled from "styled-components";
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
import Seo from "../components/Seo/Seo";

const Wrapper = styled.div`
	padding-bottom: 60px;
	.about-img {
		height: 90vh;
		margin-bottom: 30px;
		background-color: rgba(41, 59, 92, 0.5);
	}
	h1 {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 26px;
		white-space: pre-wrap;
		color: #fff;
	}
`;

const BlogTemplate = ({ data }) => {
	const markdown = data.markdownRemark;
	const image = getImage(markdown.frontmatter.Imageblog);
	// const BackgroundImage = markdown.frontmatter.Imageblog
	// const image = getImage(BackgroundImage)
	// // Use like this:
	const bgImage = convertToBgImage(image);
	const title = markdown.frontmatter.title;
	return (
		<Layout>
			<Seo title={title} description={markdown.excerpt} />
			<Wrapper>
				<BackgroundImage
					// Spread bgImage into BackgroundImage:
					Tag="div"
					className="about-img"
					{...bgImage}
					preserveStackingContext
				>
					<h1>{title}</h1>
				</BackgroundImage>

				<div
					className="body-blog globalWrapper"
					dangerouslySetInnerHTML={{ __html: markdown.html }}
				/>
			</Wrapper>
			<ScrollToTop />
		</Layout>
	);
};

export default BlogTemplate;

export const query = graphql`
query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      excerpt(pruneLength: 160)
      frontmatter {
        title
        shortdescription
        imageblogalt
        Imageblog {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, width: 3000)
          }
        }
      }
    }
  }
`